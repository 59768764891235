import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore, plugnotasConfiguracaoStore, financeiroStore, plugnotasNfeStore, plugnotasStore, } from '@/store';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import PlugnotasConsultaEmiteButton from '@/components/PlugnotasConsultaEmiteButton.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import ShowPopup from '@/components/ShowPopup.vue';
import DialogNFE from '@/views/main/notaFiscal/DialogNFE.vue';
import { downloadZip } from '@/utils';
import { differenceInDays } from 'date-fns';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headersNFeTrocaDevolucao = [
            { text: 'Emissão', value: 'plugnotas_data_emissao' },
            { text: 'NF', value: 'plugnotas_id' },
            { text: 'Descrição', value: 'items' },
            { text: 'Observação', value: 'observacao' }
        ];
        this.radioGroup = 'V';
        this.loading = false;
        this.loadingGerandoXml = false;
        this.search = '';
        this.configuracaoIntegracao = [];
        this.selectPeriodDate = null;
        this.nfceProdutosCadastradosItems = null;
        this.plugnotasConfiguracao = null;
        this.tabelaRef = null;
        this.tab = 'nfe';
        this.itemsNFeTrocaDevolucao = [];
    }
    get headers() {
        const array = [
            { text: 'Vencimento', value: 'data_vencimento' },
            { text: 'Emissão', value: 'plugnotas_data_emissao' },
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Valor Integral', value: 'valor_integral' },
            { text: 'Total Pago', value: 'total_pago' },
            { text: 'Observação', value: 'observacao' },
            { text: 'NF', value: 'plugnotas_id' },
        ];
        return array;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get notaFiscaisNfes() {
        return plugnotasNfeStore.notaFiscaisNfes;
    }
    getCompanyId() {
        return (parseInt(this.$route.params.id, 0) || mainStore.userProfile.company.id);
    }
    allowNF(financeiroCategoriaId) {
        return _.some(this.nfceProdutosCadastradosItems, (i) => i.financeiro_categoria_id == financeiroCategoriaId);
    }
    get getNFEs() {
        return financeiroStore.financeirosMateriaisDidaticoNFe.map((item) => {
            return { ...item, selected: false };
        });
    }
    async showNFE() {
        const form = this.$refs.emissaoNFE;
        form.show();
    }
    async buscar() {
        this.loading = true;
        await financeiroStore.getFinanceirosMaterialDidatico({
            dataInicial: this.selectPeriodDate[0],
            dataFinal: this.selectPeriodDate[1],
            tipoDocumento: 3,
            tipoPesquisa: this.radioGroup,
        });
        this.loading = false;
    }
    async buscarNFeTrocaDevolucao() {
        this.loading = true;
        await plugnotasNfeStore.getNfeTrocaDevolucao({
            dataInicial: this.selectPeriodDate[0],
            dataFinal: this.selectPeriodDate[1],
        });
        this.loading = false;
        const form = this.$refs.emissaoNFE;
        form.close();
    }
    async handleGerarXmlPeriodo() {
        const differenceDays = differenceInDays(new Date(this.selectPeriodDate[1]), new Date(this.selectPeriodDate[0]));
        if (differenceDays > 31) {
            this.$swal({
                title: 'Erro ao gerar nesse intervalo',
                text: 'Somente é possível gerar as notas no periodo máximo de 31 dias',
                icon: 'error',
                showCancelButton: false
            });
            return;
        }
        this.loadingGerandoXml = true;
        const responseData = await plugnotasStore.gerandoArquivoXmlPeriodo({ tipoNota: 'nfe', dataInicial: this.selectPeriodDate[0], dataFinal: this.selectPeriodDate[1] });
        this.loadingGerandoXml = false;
        const nomeArquivoGerado = `${this.selectPeriodDate[0]}_${this.selectPeriodDate[1]}_NFE`;
        if (responseData) {
            downloadZip(nomeArquivoGerado, responseData);
        }
    }
    async mounted() {
        this.$nextTick(() => {
            this.tabelaRef = this.$refs.tabela;
        });
        this.plugnotasConfiguracao =
            await plugnotasConfiguracaoStore.getPlugnotasConfiguracaoMe();
    }
};
List = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            PlugnotasConsultaEmiteButton,
            OpenNewTabComponent,
            ImprimirTabelaComponent,
            ShowPopup,
            DialogNFE,
        },
    })
], List);
export default List;
